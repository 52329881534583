import { action, makeAutoObservable, observable } from "mobx";
import { toJS } from "mobx";
import deepClone from "../../utils/deepClone";
import updateProficiency from "../Scoring/updateProficiency";

class SVS {
	// default accessibility settings
	defaultAccessibilitySettings = {
		general: {
			language: "english",
			tutorials: true,
			completeTutorials: [
				{name: "intro", complete: false},
				{name: "orders", complete: false},
				{name: "graph", complete: false},
				{name: "packing", complete: false},
				{name: "scoringFeedback", complete: false},
				{name: "equations", complete: false},
				{name: "notes", complete: false},
				{name: "recurringCustomers", complete: false},
				{name: "firstFailure", complete: false},
			]
		},
		audio: {
			musicVolume: 50,
			masterVolume: 50,
			sfxVolume: 50,
			uiVolume: 50,
			stereo: true,
			mute: false,
		},
		display: {
			font: "Arial",
			fontSize: 100,
			displayTheme: "light",
			highlightColor: "#FFFF00",
			tooltipHighlightColor: "#FF0000",
			visibleNonInteractableEntities: true,
			outOfFocusDimOpacity: 0.4,
			blackAndWhite: false,
			highContrast: false,
		},
		controls: {
			Enter: "Enter",
			Escape: "Escape",
			ArrowUp: "W",
			ArrowDown: "S",
			ArrowLeft: "A",
			ArrowRight: "D",
		},
	};

	mutedSoundSettings = {
		musicVolume: 50,
		masterVolume: 50,
		sfxVolume: 50,
		uiVolume: 50,
		stereo: true,
	};

	sounds = {
		click: new Audio(require("../../assets/sounds/click.mp3")), // Preload audio
	};

	accessibilitySettings = {};
	displayAccessibilityMenu = false;
	displayTutorialsMenu = false;
	kValue = 50;

	simulatorLoaded = false;

	gameSaveState = {};
	activityData = {};
	activityAttempts = {};

	activityDataFromDatabase = {};
	activityDialogueFromDatabase = {};

	captureEvents = {};

	constructor() {
		makeAutoObservable(this, {
			accessibilitySettings: observable,
			sounds: observable,
			displayAccessibilityMenu: observable,
			gameSaveState: observable,
			activityData: observable,
			mutedSoundSettings: observable,
			activityDataFromDatabase: observable,
			activityDialogueFromDatabase: observable,
			setAccessibilityAttribute: action,
			setActivityContent: action,
			setActivityDialogue: action,
			setMutedSettings: action,
			setAccessibilitySettings: action,
			resetToDefaultAccessibilitySettings: action,
			startGame: action,
			toggleDisplayAccessibilityMenu: action,
			overrideAccessibilitySettings: action,
			checkIfKeyBeingUsed: action,
			playSound: action,
			simulatorLoaded: observable,
			setSimulatorLoaded: action,
			addActivityAttempt: action,
			activityAttempts: observable,
			kValue: observable,
			setKValue: action,
			updateAnswers: action,
			captureEvents: observable,
			setActivityDataAttribute: action,
		});

		this.loadAccessibilitySettings();
		this.loadKValue();
	}

	setSimulatorLoaded = () => {
		this.simulatorLoaded = true;
	};

	// Setter function for the k value
	setKValue = value => {
		this.kValue = value;
		// Update in local storage
		localStorage.setItem("kValue", value);
	};

	// Loader function for the k value - called on initial load
	loadKValue = () => {
		const kValue = localStorage.getItem("kValue");
		if (kValue) {
			this.kValue = kValue;
		}
	};

	toggleDisplayAccessibilityMenu = () => {
		this.displayAccessibilityMenu = !this.displayAccessibilityMenu;

		if (this.displayAccessibilityMenu) {
			// if menu is open
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 0.2; // grey out the simulation window
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 0.2;
			}
		} else {
			// if menu is closed
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 1; // make the simulation window visible again
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 1;
			}
		}
		return this.displayAccessibilityMenu;
	};

	toggleDisplayTutorialsMenu = () => {
		this.displayTutorialsMenu = !this.displayTutorialsMenu;

		if (this.displayTutorialsMenu) {
			// if menu is open
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 0.2; // grey out the simulation window
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 0.2;
			}
		} else {
			// if menu is closed
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 1; // make the simulation window visible again
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 1;
			}
		}
		return this.displayTutorialsMenu;
	};

	startGame = async (gameSaveState, activityDataJson, activityAttempts) => {
		// Set the activity attempts
		this.setActivityAttempts(activityAttempts);
		// Load the game save state and set accordingly
		this.gameSaveState = this.loadGameSaveState(gameSaveState);
		// Save the game save state to local storage
		this.saveGameSaveStateToLocalStorage();
		// Load the activity data
		this.loadActivity(false, activityDataJson, true);
		console.log("Activity data json", activityDataJson);
	};

	loadGameSaveState = gameSaveState => {
		console.log("loading game save state", gameSaveState);

		if (gameSaveState) {
			if (Object.keys(gameSaveState).length !== 0) return gameSaveState;
		}

		return this.constructNewSaveState();
	};

	saveGameSaveStateToLocalStorage = () => {
		// TODO: use student's profile rather than saving to local storage
		localStorage.setItem("gameSaveState", JSON.stringify(this.gameSaveState));
	};

	restartCurrentActivity = () => {
		console.log("restarting activity");

		const newActivityData = deepClone(this.activityData);

		// reset current score
		newActivityData.currentScore = 0;

		// remove algebra data
		newActivityData.algebraData = [];

		// reset studentData attributes:
		Object.keys(newActivityData.studentData).forEach((key) => {
			if (key.startsWith('equation') && newActivityData.studentData[key]?.orderAccepted !== undefined) {
				newActivityData.studentData[key].orderAccepted = false;
			}
		});

		newActivityData.studentData.graphCoordinateX = -1;
		newActivityData.studentData.graphCoordinateY = -1;
		newActivityData.studentData.simulationCoordinateX = -1;
		newActivityData.studentData.simulationCoordinateY = -1;
		newActivityData.studentData.varX = null;
		newActivityData.studentData.varY = null;
		newActivityData.studentData.eq1VarX = null;
		newActivityData.studentData.eq1VarY = null;
		newActivityData.studentData.eq1Operator = null;
		newActivityData.studentData.eq1Result = null;
		newActivityData.studentData.eq2Result = null;
		newActivityData.studentData.acceptedOrder = null;
		newActivityData.studentData.notes = "";
		newActivityData.studentData.read = {}
		newActivityData.answerX = null;
		newActivityData.answerY = null;
		newActivityData.produceAAnswer = null;
		newActivityData.produceBAnswer = null;

		this.setActivityData(newActivityData);

		// try to call the reset function in the engine files
		try {
			window.restartActivityInEngine();
		} catch (error) {
			console.warn(error);
		}
	};

	moveOnToNextActivity = () => {
		console.log("moving on to next activity");

		const newGameSaveState = deepClone(this.gameSaveState);
		console.log("new game save state", newGameSaveState);

		const userPassed = this.activityData.bestScore >= this.activityData.passThreshold;

		/* 
            update friendship scores for the orders that were accepted:
            - if the friendship score is less than 5, increase it by 1, only if the user passed the activity
            - if the friendship score is 5, do not increase it any more
            - if the friendship score is 1, always increase it, even if the user failed the activity
        */
		this.activityData.orderData.forEach((order) => {
			const character = order.character;
		
			if (
				(userPassed && newGameSaveState.friendshipScores[character] < 5) ||
				newGameSaveState.friendshipScores[character] === 1
			) {
				newGameSaveState.friendshipScores[character]++;
			}
		});

		// update proficiency score
		newGameSaveState.proficiencyScore = updateProficiency(
			this.gameSaveState.proficiencyScore,
			this.activityData.difficultyScore,
			userPassed ? 1 : 0,
			this.kValue,
		);

		// Ensure the failed activities array exists
		if (!newGameSaveState.failedActivities) {
			newGameSaveState.failedActivities = [];
		}

		// Updating the users passes and failed activities
		// If user passed activity
		if (userPassed) {
			// Remove all failed activities below the current proficiency score
			newGameSaveState.failedActivities = newGameSaveState.failedActivities.filter(
				activityID => this.activityData.difficultyScore < this.activityDataFromDatabase[activityID].difficultyScore
			);
			// Remove from failed activities if it exists
			const failedIndex = newGameSaveState.failedActivities.indexOf(this.activityData.id);
			if (failedIndex > -1) newGameSaveState.failedActivities.splice(failedIndex, 1);
			// Add to passed activities
			newGameSaveState.passedActivities.push(this.activityData.id);
		}
		else { // If failed activity
			// Remove from passed activities if it exists
			if (!newGameSaveState.failedActivities.includes(this.activityData.id)) {
			newGameSaveState.failedActivities.push(this.activityData.id);
			}
		}

		newGameSaveState.seenDialogueIDs.push(
			...this.activityData.orderData.map(order => order.dialogueID)
		);

		// update the game save state
		this.setGameSaveState(newGameSaveState);

		// restart the game with the new game save state - if user passes will look for next activity upwards and vice versa if fails
		if (userPassed){
			// Load activity data
			this.loadActivity(true, null, true);
		}
		else{
			// Load activity data
			this.loadActivity(true, null, false);
		}

		// try to call the reset function in the engine files
		try {
			window.restartActivityInEngine();
		} catch (error) {
			console.warn(error);
		}
	};

	setGameSaveState = gameSaveStateData => {
		this.gameSaveState = {
			...JSON.parse(JSON.stringify(gameSaveStateData)),
		};

		this.saveGameSaveStateToLocalStorage(this.gameSaveState);
	};

	constructNewSaveState = () => {
		// for now, we will hard code the characters
		const defaultGameSaveState = {
			friendshipScores: {
				Kai: 1,
				Francisco: 1,
				Harold: 1,
				Zach: 1,
				Sophia: 1,
				Natalie: 1,
				Chris: 1,
				Luis: 1,
				Rebecca: 1,
				Tae: 1,
				Madison: 1,
				Olivia: 1,
				Emily: 1,
				Anthony: 1,
				Bill: 1,
				Dani: 1,
				Gabi: 1,
				Justin: 1,
			},
			seenDialogueIDs: [],
			passedActivities: [],
			failedActivities: [],
			proficiencyScore: 1000,
		};

		const characterRoster = Object.keys(this.activityDialogueFromDatabase);

		if (!this.activityDialogueFromDatabase || characterRoster.length === 0) return defaultGameSaveState;

		// this will only work if the activityDialogueFromDatabase pulls all characters from the database
		for (const character of characterRoster) {
			defaultGameSaveState.friendshipScores[character] = 1;
		}

		return defaultGameSaveState;
	};

	loadActivity(loadingNewActivity = false, activityDataJson = null, pass=true, ) {
		// If not loading a new activity and a non-empty activityDataJson is passed, set the activity data, otherwise load a new activity
		if (activityDataJson) {
			if (activityDataJson && !loadingNewActivity) {
				this.setActivityData(activityDataJson);
				return;
			}
		}

		// figure out what Elo (proficiency) score the student is at
		const prof = this.gameSaveState.proficiencyScore;

		const rawActivityData = this.getActivityByProficiency(prof, pass);

		const activityData = this.constructActivity(rawActivityData);

		this.setActivityData(activityData);

		return;
	}

	setActivityAttempts(attempts) {
		this.activityAttempts = attempts;
	}

	addActivityAttempt = (activityID, attemptData) => {
		if (!this.activityAttempts[activityID]) {
			this.activityAttempts[activityID] = {};
		}

		const attemptCount = Object.keys(this.activityAttempts[activityID]).length;

		this.activityAttempts = {
			...this.activityAttempts,
			[activityID]: {
				...this.activityAttempts[activityID],
				[attemptCount + 1]: attemptData,
			},
		};
		return toJS(this.activityAttempts);
	};

	constructActivity = rawActivityData => {
		// Get the activity data
		const activityData = { ...rawActivityData };
		// Set answers to null, cant determine this until an order has been accepted
		activityData.answerX = null;
		activityData.answerY = null;

		activityData.produceAAnswer = null;
		activityData.produceBAnswer = null;

		// Get the number of orders
		const numOrders = activityData.orderData.length;

		// Dynamically select the required number of characters
		const selectedCharacters = this.selectCharacters(activityData.characterRoster, numOrders);

		// Assign selected characters to the orders
		activityData.orderData.forEach((order, index) => {
			order.character = selectedCharacters[index];
		});

		// Assign characters and dialogues dynamically depending on the frienship score
		activityData.orderData.forEach((order, index) => {
			const character = selectedCharacters[index];
			order.character = character;

			// Assign dialogue and friendship score
			[order.dialogueID, order.characterDialogue] = this.getDialogue(order);
			order.friendshipScore = this.gameSaveState.friendshipScores[character];
		});

		// Convert student data arrays to more complex objects dynamically
		const studentData = activityData.studentData.map((data, index) => {
			const order = activityData.orderData[index];
			return {
				...deepClone(data),
				orderAccepted: false,
				produceA: order.produceA,
				produceB: order.produceB,
			};
		});

		// Assign structured student data
		activityData.studentData = studentData.reduce((acc, data, index) => {
			acc[`equation${index + 1}`] = data;
			return acc;
	}, {});


		activityData.studentData.graphCoordinateX = -1;
		activityData.studentData.graphCoordinateY = -1;
		// Graph tab variables
		activityData.studentData.simulationCoordinateX = -1;
		activityData.studentData.simulationCoordinateY = -1;
		// Equation tab variables
		activityData.studentData.varX = null;
		activityData.studentData.varY = null;
		activityData.studentData.eq1VarX = null;
		activityData.studentData.eq1VarY = null;
		activityData.studentData.eq1Operator = null;
		activityData.studentData.eq1Result = null;
		activityData.studentData.eq2Result = null;
		// Orders tab variables
		activityData.studentData.acceptedOrder = null;
		// Notes tab
		activityData.studentData.notes = "";
		// Create read variables for each order dynamically for number of orders
		activityData.studentData.read = {};
		for (let i = 0; i < numOrders; i++) {
			activityData.studentData.read[i] = false;
		}

		activityData.retries = 3;
		activityData.currentScore = 0;
		activityData.bestScore = 0;

		return activityData;
	};

	/**
	 * Used for updating the answers for the equation passed in - called when order has been accepted
	 * @param {Object} acceptedOrderEquation - {a: producePriceA, b: producePriceB, c: budget}
	 * @param {Object} spaceEquation - {a: 1, b: 1, c: spaceConstraint}
	 * @returns 
	 */
	updateAnswers = (acceptedOrderEquation, spaceEquation) => {
		// If the equations are not valid, set the answers to null
		console.log("updating answers", acceptedOrderEquation, spaceEquation);
		if(!acceptedOrderEquation || !spaceEquation){
			this.setActivityDataAttribute("answerX", null);
			this.setActivityDataAttribute("answerY", null);
			return;
		}
		const {x, y} = this.solveSimultaneousEquations(acceptedOrderEquation, spaceEquation);
		// Set the answers
		this.setActivityDataAttribute("answerX", x);
		this.setActivityDataAttribute("answerY", y);
		this.setActivityDataAttribute("produceAAnswer", x);
		this.setActivityDataAttribute("produceBAnswer", y);
	};


	setActivityData = activityData => {
		this.activityData = {
			...JSON.parse(JSON.stringify(activityData)),
		};

		this.saveActivityDataToLocalStorage();
	};

	/**
	 * Function for updating an attribute of one of the equations in the student data
	 * @param {Integer} equation - The equation number to set the attribute for
	 * @param {String} attribute - The attribute to set
	 * @param {} value - The value to set the attribute to
	 */
	setStudentEquationAttribute = (equation, attribute, value) => {
		const equationKey = `equation${equation}`;
	
		if (
			Object.prototype.hasOwnProperty.call(this.activityData.studentData[equationKey], attribute)
		) {
			this.activityData = {
				...this.activityData,
				studentData: {
					...this.activityData.studentData,
					[equationKey]: {
						...this.activityData.studentData[equationKey],
						[attribute]: value,
					},
				},
			};
			this.saveActivityDataToLocalStorage();
		}
	};
	

	setAlgebraData = algebraData => {
		this.activityData = {
			...this.activityData,
			algebraData: algebraData,
		};

		this.saveActivityDataToLocalStorage();
	};

	setStudentActivityAttribute = (attribute, value, equation = null) => {
		if (equation) {
			this.setStudentEquationAttribute(equation, attribute, value);
			return;
		}
		if (Object.prototype.hasOwnProperty.call(this.activityData.studentData, attribute)) {
			this.activityData = {
				...this.activityData,
				studentData: {
					...this.activityData.studentData,
					[attribute]: value,
				},
			};
			this.saveActivityDataToLocalStorage();
		}
	};

	saveActivityDataToLocalStorage = () => {
		localStorage.setItem("activityData", JSON.stringify(this.activityData));
	};

	setActivityDataAttribute = (attribute, value) => {
		console.log("setting attribute", attribute, value);

		if (Object.prototype.hasOwnProperty.call(this.activityData, attribute)) {
			this.activityData = {
				...this.activityData,
				[attribute]: value,
			};
		}

		this.saveActivityDataToLocalStorage();
	};

	/**
	 * gets the activity based on the passed activity ID
	 *
	 * @param {Int} activityID - The ID of the activity to get
	 * @returns the activity object
	 */
	getActivityByID = activityID => {
		// pull the activity data from the database
		return this.activityDataFromDatabase[activityID];
	};

	/**
	 * gets a random activity based on the passed proficiency score
	 *
	 * @param {Int} proficiencyScore - The proficiency score of the student
	 * @returns {Object} - The randomly selected activity object
	 */
	getActivityByProficiency = (proficiencyScore, pass=true )=> {
		// organize the activities in this.activityDataFromDatabase by proficiency score
		const sortedActivities = Object.entries(this.activityDataFromDatabase)
			.map(([key, activity]) => ({
				...activity,
				id: Number(key), // save the id inside the sorted object to use as a reference later
			}))
			.sort((a, b) => a.difficultyScore - b.difficultyScore);
		//return sortedActivities[8];
		let selectedActivity = null;
		let filteredActivities = [];
		// If user passed the previous activity
		if (pass){
			// remove all activities from the list that appear in gameSaveState.passedActivities
			filteredActivities = sortedActivities.filter(
				activity => !this.gameSaveState.passedActivities.includes(activity.id),
			);

			// choose the next highest activity based on the passed proficiency score
			for (let i = 0; i < filteredActivities.length; i++) {
				if (filteredActivities[i].difficultyScore >= proficiencyScore) {
					selectedActivity = filteredActivities[i];
					break;
				}
			}
			// if no activities are left, choose the closest lower score activity
			if (!selectedActivity) selectedActivity = filteredActivities[filteredActivities.length - 1];
			if (!selectedActivity) throw new Error("No activities left for user to do");
		}
		else{
			// Filter out the failed activitiies and get the next lowest activity
			filteredActivities = sortedActivities.filter(
				activity => !this.gameSaveState.failedActivities.includes(activity.id),
			);
			// Filter out all activities with a higher difficulty score than the proficiency score
			filteredActivities = filteredActivities.filter(
				activity => activity.difficultyScore <= proficiencyScore,
			);
			// Choose the next lowest activity based on the proficiency score
			for (let i = filteredActivities.length - 1; i >= 0; i--) {
				if (filteredActivities[i].difficultyScore <= proficiencyScore) {
					selectedActivity = filteredActivities[i];
					break;
				}
			}
			// If no activities are left, choose the closest higher score activity
			if (!selectedActivity) selectedActivity = filteredActivities[0];
			if (!selectedActivity) throw new Error("No Activities below this for user to do");
		}
		return selectedActivity;
	};

	getStudentProficiency = () => {
		// TODO: pull the students proficiency from whatever data base we are using and return it
		return 900;
	};

	loadAccessibilitySettings = () => {
		// Load the accessibility JSON from local storage
		const localAccessibilitySettings = localStorage.getItem("accessibilitySettings");

		if (localAccessibilitySettings) {
			// Set it to the json from local storage if it exists
			this.accessibilitySettings = JSON.parse(localAccessibilitySettings);

			// redundant code?
			this.setAccessibilitySettings();
		} else {
			// Otherwise, set it to the default accessibility settings
			this.resetToDefaultAccessibilitySettings();
		}
	};

	setAccessibilitySettings = () => {
		// Update local storage for persistency
		localStorage.setItem("accessibilitySettings", JSON.stringify(this.accessibilitySettings));
	};

	setMutedSettings = () => {
		this.mutedSoundSettings = deepClone(this.accessibilitySettings.audio);
	};

	setAccessibilityAttribute = (heading, attribute, value) => {
		if (Object.prototype.hasOwnProperty.call(this.accessibilitySettings, heading)) {
			if (Object.prototype.hasOwnProperty.call(this.accessibilitySettings[heading], attribute)) {
				// Create a new object for the heading
				const updatedHeading = {
					...this.accessibilitySettings[heading],
					[attribute]: value,
				};

				// Create a new object for the accessibility settings
				this.accessibilitySettings = {
					...this.accessibilitySettings,
					[heading]: updatedHeading,
				};

				console.log("successfully set accessibility attribute");
			} else {
				console.warn(`Attribute '${attribute}' does not exist in heading '${heading}'.`);
				return;
			}
		} else {
			console.warn(`Heading '${heading}' does not exist in accessibility settings.`);
			return;
		}

		// Update local storage for persistency
		this.setAccessibilitySettings();
	};

	resetToDefaultAccessibilitySettings = () => {
		this.accessibilitySettings = deepClone(this.defaultAccessibilitySettings);

		// Update local storage for persistency
		this.setAccessibilitySettings();

		console.log("reset to default accessibility settings");
	};

	overrideAccessibilitySettings = newAccessibilitySettings => {
		this.accessibilitySettings = deepClone(newAccessibilitySettings);

		this.setAccessibilitySettings();
	};

	checkIfKeyBeingUsed = key => {
		const { controls } = this.accessibilitySettings;
		const keys = Object.values(controls);
		return keys.includes(key);
	};

	/**
	 * Plays a sound
	 * @param {String} sound - the name of the string of the sound you want to play
	 */
	playSound = sound => {

		// Get the volume from the accessibility store
		if (this.sounds[sound] === undefined) {
			console.error("Sound not found");
			return;
		}
		const volume = this.accessibilitySettings.audio.uiVolume / 100;
		this.sounds[sound].volume = volume;
		// Set the time to be zero so that the sound can be played multiple times at once
		this.sounds[sound].currentTime = 0;
		this.sounds[sound].play();
	};

	/**
	 * Solves a pair of simultaneous equations
	 * @param {Object} equation1 - the first equation to solve
	 * @param {Object} equation2 - the second equation to solve
	 * @returns {Object} - the values of x and y that solve the equations
	 */
	solveSimultaneousEquations = (equation1, equation2) => {
		const { a: a1, b: b1, c: c1 } = equation1;
		const { a: a2, b: b2, c: c2 } = equation2;
		// Calculate the determinant
		const determinant = a1 * b2 - a2 * b1;

		// Check if the equations are parallel or coincident
		if (determinant === 0) {
			if (c1 === c2) return "The equations are coincident, infinite solutions exist.";

			return "The equations are parallel, no solution exists.";
		}

		// Calculate the values of x and y
		const x = (b2 * c1 - b1 * c2) / determinant;
		const y = (a1 * c2 - a2 * c1) / determinant;
		return { x, y };
	};

	selectCharacters = (roster, numCharacters) => {
		const weightedRoster = [];
	
		/* 
			Loop over all characters in the roster and add them to the weightedRoster array. 
			The number of times a character appears in the weightedRoster array is determined 
			by their friendship score. For example, if Kai's friendship is 3, he will appear in the
			weightedRoster array 3 times, making him 3 times more likely to be selected.
		*/
		for (const character of roster) {
			const score = this.gameSaveState.friendshipScores[character];
	
			if (score === undefined) {
				console.error("Character not found in friendship scores:", character);
				return [];
			}
	
			for (let i = 0; i < score; i++) {
				weightedRoster.push(character);
			}
		}
	
		const selectedCharacters = new Set();
	
		// Randomly select the required number of characters, ensuring uniqueness
		while (selectedCharacters.size < numCharacters && weightedRoster.length > 0) {
			const randomIndex = Math.floor(Math.random() * weightedRoster.length);
			const character = weightedRoster.splice(randomIndex, 1)[0];
			selectedCharacters.add(character);
		}
	
		return Array.from(selectedCharacters);
	};
	

	getDialogue = equationData => {
		const { character, produceA, producePriceA, produceB, producePriceB, budget } = equationData;
		
		const dialogueForCharacter = this.activityDialogueFromDatabase[character];

		const characterFriendshipScore = this.gameSaveState.friendshipScores[character];
		if (!dialogueForCharacter[characterFriendshipScore])
			console.error(`No dialogue found for character ${character} with friendship score ${characterFriendshipScore}`);

		const potentialDialogues = Object.keys(
			this.activityDialogueFromDatabase[character][this.gameSaveState.friendshipScores[character]],
		);

		const randomDialogueID = potentialDialogues[Math.floor(Math.random() * potentialDialogues.length)];

		let chosenDialogue = dialogueForCharacter[characterFriendshipScore][randomDialogueID];

		chosenDialogue = chosenDialogue.replace(/\$orderProduceA\$/g, `<span class='font-bold'>${produceA}</span>`);

		// handle the case where dollar sign was prefixed
		chosenDialogue = chosenDialogue.replace(/\$\$orderPriceA\$/g, `<span class='font-bold'>$${producePriceA}</span>`);
		// if double dollar sign string wasn't found then just handle the single dollar sign case
		chosenDialogue = chosenDialogue.replace(/\$orderPriceA\$/g, `<span class='font-bold'>$${producePriceA}</span>`);

		chosenDialogue = chosenDialogue.replace(/\$orderProduceB\$/g, `<span class='font-bold'>${produceB}</span>`);

		// handle the case where double dollar sign was prefixed
		chosenDialogue = chosenDialogue.replace(/\$\$orderPriceB\$/g, `<span class='font-bold'>$${producePriceB}</span>`);
		// if double dollar sign string wasn't found then just handle the single dollar sign case
		chosenDialogue = chosenDialogue.replace(/\$orderPriceB\$/g, `<span class='font-bold'>$${producePriceB}</span>`);

		chosenDialogue = chosenDialogue.replace(/\$budget\$/g, `<span class='font-bold'>$${budget}</span>`);

		return [randomDialogueID, chosenDialogue];
	};

	setActivityContent = data => {
		this.activityDataFromDatabase = data;
	};

	setActivityDialogue = data => {
		this.activityDialogueFromDatabase = data;
	};

	setCompleteTutorials = (tutorialName, complete = true) => {
		const tutorialIndex = this.accessibilitySettings.general.completeTutorials.findIndex(
			tutorial => tutorial.name === tutorialName,
		);

		if (tutorialIndex === -1) {
			console.error("Tutorial not found");
			return;
		}

		this.accessibilitySettings.general.completeTutorials[tutorialIndex].complete = complete;

		this.setAccessibilitySettings();

		return this.accessibilitySettings.general.completeTutorials[tutorialIndex+1];
	}

	// getLastCompletedTutorial = () => {
	// 	const lastCompletedTutorial = this.accessibilitySettings.general.completeTutorials.find(
	// 		tutorial => tutorial.complete === true,
	// 	);

	// 	return lastCompletedTutorial;
	// }

	getNextIncompleteTutorial = () => {

		const nextIncompleteTutorial = this.accessibilitySettings.general.completeTutorials.find(
			tutorial => tutorial.complete === false,
		);

		if (!nextIncompleteTutorial) return null;

		return nextIncompleteTutorial;

		// const lastCompletedTutorial = this.getLastCompletedTutorial();

		// if (!lastCompletedTutorial) return this.accessibilitySettings.general.completeTutorials[0];

		// const lastCompletedTutorialIndex = this.accessibilitySettings.general.completeTutorials.findIndex(
		// 	tutorial => tutorial.name === lastCompletedTutorial.name,
		// );

		// if (lastCompletedTutorialIndex === -1) {
		// 	console.error("Tutorial not found");
		// 	return;
		// }

		// if (lastCompletedTutorialIndex === this.accessibilitySettings.general.completeTutorials.length - 1) {
		// 	return null;
		// }

		// return this.accessibilitySettings.general.completeTutorials[lastCompletedTutorialIndex + 1];
	}
}

export const svs = new SVS();
